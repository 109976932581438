<template>
  <v-card tile flat :loading="pending.section" width="100%">
    <v-row v-if="!pending.section" class="ma-0">
      <v-col cols="12">
        <validation-observer ref="editSection">
          <v-form style="width: 100%">
            <v-row class="ma-0 pa-0 lbm_section-card">
              <v-col class="lbm_section-card__image elevation-2 rounded">
                <v-img class="d-flex justify-end align-end" max-height="100%" max-width="100%" :src="section.image.src">
                  <v-row class="ma-0 pa-0">
                    <v-col cols="12" class="ma-0 d-flex justify-end align-end">
                      <v-spacer></v-spacer>
                      <v-btn @click="changeImage" fab small color="primary">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col class="lbm_section-card__editing pt-0">
                <v-card width="100%" max-height="100%">
                  <v-card-text>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" :lg="$options.filters.isObjectPropertyNull(section.properties) ? 12 : 6">
                        <validation-provider>
                          <label class="d-block darken-4 mb-2 font-weight-bold">{{ $t('forms.section.edit.name') }}</label>
                          <v-text-field id="sectionName"
                                        solo
                                        :placeholder="$t('forms.section.edit.name')"
                                        v-model="section.name"></v-text-field>
                        </validation-provider>
                        <validation-provider>
                          <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.edit.route') }}</label>
                          <v-text-field solo :label="$t('forms.section.edit.route')"
                                        v-model="section.route"></v-text-field>
                        </validation-provider>
                        <v-expansion-panels :value="0">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-bold">{{ $t('forms.section.edit.content_title') }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-card tile flat>
                                <div class="my-2">
                                  <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.edit.content_first_paragraph') }}</label>
                                  <div class="elevation-2 rounded">
                                    <editor :extensions="extensions"
                                            style="overflow: auto; max-height: 200px"
                                            v-model="section.content.content_first_paragraph">
                                    </editor>
                                    <label class="d-block text-right mb-2 pl-6 pr-6 pb-3 pt-3">
                                      <span>{{ section.content.content_first_paragraph | countWords }}</span>
                                    </label>
                                  </div>
                                </div>
                                <div class="my-6">
                                  <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.edit.content') }}</label>
                                  <div class="elevation-2 rounded">
                                    <editor :extensions="extensions"
                                            style="overflow: auto; max-height: 400px"
                                            v-model="section.content.content">
                                    </editor>
                                    <label class="d-block text-right mb-2 pl-6 pr-6 pb-3 pt-3">
                                      <span>{{ section.content.content | countWords }} / 500</span>
                                    </label>
                                  </div>
                                </div>
                                <template v-if="section.content.content_external">
                                  <div>
                                    <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.edit.content_external') }}</label>
                                    <div class="elevation-2 rounded">
                                      <editor :extensions="extensions"
                                              style="overflow: auto; max-height: 400px"
                                              v-model="section.content.content_external">
                                      </editor>
                                      <label class="d-block text-right mb-2 pl-6 pr-6 pb-3 pt-3">
                                        <span>{{ section.content.content_external | countWords }} / 500</span>
                                      </label>
                                    </div>
                                  </div>
                                </template>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <template v-if="section.is_food">
                          <v-expansion-panels :value="section.is_food ? 0 : undefined" class="my-6">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="font-weight-bold">{{ $t('forms.section.nutrients.titles.expansion') }}</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <div class="my-3">
                                  <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.nutrients.titles.nutrients') }}</label>
                                  <div class="ma-0 pa-0 lbm_section-card__nutrients">
                                    <div class="my-2" v-for="(nutrient, key) in section.nutrients" :key="key">
                                      <validation-provider>
                                        <label class="d-block mb-2">{{ $t(`forms.section.nutrients.${key}`) }}</label>
                                        <v-text-field solo
                                                      :placeholder="$t('forms.section.edit.name')"
                                                      :value="nutrient"></v-text-field>
                                      </validation-provider>
                                    </div>
                                  </div>
                                </div>
                                <div class="my-16">
                                  <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.nutrients.titles.vitamins') }}</label>
                                  <div class="ma-0 pa-0 lbm_section-card__nutrients">
                                    <div class="my-2" v-for="(vitamin, key) in section.vitamins" :key="key">
                                      <validation-provider>
                                        <label class="d-block mb-2">{{ $t(`forms.section.nutrients.${key}`) }}</label>
                                        <v-text-field solo
                                                      :placeholder="$t('forms.section.edit.name')"
                                                      :value="vitamin"></v-text-field>
                                      </validation-provider>
                                    </div>
                                  </div>
                                </div>
                                <div class="my-16">
                                  <label class="d-block mb-2 font-weight-bold">{{ $t('forms.section.nutrients.titles.minerals') }}</label>
                                  <div class="ma-0 pa-0 lbm_section-card__nutrients">
                                    <div class="my-2" v-for="(metal, key) in section.metals" :key="key">
                                      <validation-provider>
                                        <label class="d-block mb-2">{{ $t(`forms.section.nutrients.${key}`) }}</label>
                                        <v-text-field solo
                                                      :placeholder="$t('forms.section.edit.name')"
                                                      :value="metal"></v-text-field>
                                      </validation-provider>
                                    </div>
                                  </div>
                                </div>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </v-col>
                      <v-col cols="12" :lg="$options.filters.isObjectPropertyNull(section.properties) ? 12 : 6">
                        <template v-if="!$options.filters.isObjectPropertyNull(section.properties)">
                          <v-expansion-panels :value="0" class="my-6">
                            <v-expansion-panel>
                              <v-expansion-panel-header class="font-weight-bold">{{ $t('forms.section.properties.title') }}</v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <template v-for="(property, key) in section.properties">
                                  <template v-if="property">
                                    <div class="my-6" :key="key">
                                      <label class="d-block mb-2 font-weight-bold">{{ $t(`forms.section.properties.${key}`) }}</label>
                                      <div class="elevation-2 rounded" style="min-height: 200px">
                                        <editor :extensions="extensions"
                                                style="overflow: auto; height: 100%"
                                                :value="property">
                                        </editor>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </template>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pa-6 d-flex justify-end">
                    <v-btn @click="submit" color="primary" dark>{{ $t('buttons.update') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col class="lbm_section-card__additions">
                <v-expansion-panels :value="0">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="text-body-1 font-weight-bold">{{ $t('buttons.additionsButtons') }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-card flat tile class="pa-0">
                        <v-card-actions class="d-flex flex-wrap pa-0">
                          <v-btn @click="addToSection('properties')" color="primary" dark class="ma-2">{{ $t('forms.section.properties.add') }}</v-btn>
                          <v-menu transition="slide-y-transition" bottom>
                            <template v-slot:activator="{ on, attr }">
                              <v-btn v-bind="attr"
                                     v-on="on"
                                     color="primary" dark
                                     class="ma-2">{{ $t('buttons.translate') }}</v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="translate(lang)" v-for="(lang, key) in languages" :key="key" link>
                                <v-list-item-icon>
                                  <v-icon color="primary" small>mdi-translate</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ lang.title }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <template v-if="section.icon">
                            <v-btn color="primary" dark class="ma-2">{{ $t('forms.section.edit.icon.edit') }}</v-btn>
                          </template>
                          <template v-else>
                            <v-btn color="primary" dark class="ma-2">{{ $t('forms.section.edit.icon.add') }}</v-btn>
                          </template>
                          <v-btn @click="createNew" color="primary" dark class="ma-2">{{ $t('buttons.newRelated') }}</v-btn>
                          <v-btn color="primary" dark class="ma-2">{{ $t('buttons.tags') }}</v-btn>
                          <template v-if="!section.content.content_external">
                            <v-btn @click="addToSection('content_external')" color="primary" dark class="ma-2">{{ $t('buttons.contentExternalAdd') }}</v-btn>
                          </template>
                        </v-card-actions>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <template v-if="section.children.length > 0">
                  <v-expansion-panels :value="section.children.length > 0 ? 0 : undefined">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <span class="text-body-1 font-weight-bold">{{ $t('forms.section.children') }}</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pb-4">
                        <v-card class="pt-0 pr-0 pl-0" tile flat max-height="400" style="overflow-y: auto" width="100%">
                          <v-card-text class="pt-0 pr-0 pl-0">
                            <v-treeview style="width: 100%"
                                        hoverable
                                        expand-icon="mdi-chevron-down"
                                        activatable
                                        item-key="name"
                                        :items="section.children">
                              <template v-slot:prepend="{ open }">
                                <v-icon>{{ open ? 'mdi-tray' : 'mdi-tray-full' }}</v-icon>
                              </template>
                              <template v-slot:append="{ item }">
                                <v-btn color="primary" dark fab x-small @click="toSection(item)">
                                  <v-icon small>mdi-arrow-right</v-icon>
                                </v-btn>
                              </template>
                            </v-treeview>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
      <v-dialog v-model="props" :max-width="$vuetify.breakpoint.width / 1.4" scrollable>
        <v-card min-height="100%">
          <v-card-title class="elevation-2">
            <v-spacer></v-spacer>
            <v-btn @click="closeAddToSection" color="error" fab small>
              <v-icon dark small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <template v-if="add.properties">
            <v-card-text class="lbm_section-card__properties"
                         :style="`grid-auto-rows: calc(${$vuetify.breakpoint.height}px / 3)`">
              <v-card-text v-for="(property, key) in section.properties" :key="key">
                <template v-if="!property">
                  <div class="fill-height">
                    <label class="d-block my-2">{{ $t(`forms.section.properties.${key}`) }}</label>
                    <div class="elevation-2 rounded fill-height">
                      <editor :extensions="extensions"
                              :cardProps="{ height: '100%' }"
                              style="overflow: auto; height: 100%"
                              :value="property">
                      </editor>
                    </div>
                  </div>
                </template>
              </v-card-text>
            </v-card-text>
          </template>
          <template v-if="add.content_external">
            <v-card-text class="pa-0">
              <editor :extensions="extensions"
                      :cardProps="{ minHeight: $vuetify.breakpoint.height * 0.94 }"
                      v-model="section.content.content_external">
              </editor>
            </v-card-text>
          </template>
        </v-card>
      </v-dialog>
      <v-dialog scrollable :max-width="$vuetify.breakpoint.width / 2" v-model="newImage">
        <v-card>
          <v-card-title class="elevation-2">
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" small fab color="error" dark>
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row class="ma-0" :style="`position: relative; height: ${$vuetify.breakpoint.height / 1.5}px`">
              <v-col cols="12" style="height: 80%; overflow-y: auto; overflow-x: hidden">
                <div class="d-flex justify-center mb-4">
                  <span class="title">{{ $t('image.dialog.title') }}</span>
                </div>
                <images/>
              </v-col>
              <v-col cols="12" class="d-flex justify-center align-center" style="height: 20%">
                <div class="d-flex justify-center flex-column">
                  <div class="d-block mb-4">
                    <span class="title">{{ $t('image.dialog.subtitle') }}</span>
                  </div>
                  <v-btn @click="openAddNewImageDialog" color="primary">{{ $t('buttons.choose') }}</v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <template v-if="editImage">
        <v-dialog :max-width="$vuetify.breakpoint.width / 1.7" v-model="editImage">
          <v-card>
            <v-card-title class="elevation-2">
              <v-spacer></v-spacer>
              <v-btn @click="closeAddNewImageDialog" small fab color="error" dark>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="ma-0 d-flex justify-center">
                <template v-for="(value, key) in section.image">
                  <template v-if="$options.filters.inArray(key, hiddenKeys)"></template>
                  <template v-else>
                    <v-col :key="key" cols="6" class="d-flex flex-column justify-start">
                      <template v-if="key !== 'src' && key !== 'file'">
                        <label class="my-2">{{ $t(`image.${key}.label`) }}</label>
                        <v-text-field dense :placeholder="$t(`image.${key}.placeholder`)"
                                      solo v-model="section.image[key]"></v-text-field>
                      </template>
                      <template v-if="key === 'src'">
                        <label class="my-2">{{ $t(`image.${key}.label`) }}</label>
                        <v-file-input
                          @change="previewNewImage"
                          accept="image/*"
                          dense
                          solo
                          :placeholder="$t(`image.${key}.placeholder`)"
                          filled
                          prepend-icon=""
                        >
                          <template v-slot:prepend-inner>
                            <v-icon small>mdi-camera</v-icon>
                          </template>
                        </v-file-input>
                      </template>
                    </v-col>
                  </template>
                </template>
              </v-row>
            </v-card-text>
            <v-card-actions class="pr-8 pb-8">
              <v-spacer/>
              <v-btn color="primary" dark @click="closeAddNewImageDialog">{{ $t('buttons.save') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-row>
  </v-card>
</template>
<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import Images from '@/views/admin/Images'
export default {
  name: 'Section',
  components: {
    editor: TiptapVuetify,
    Images
  },
  data () {
    return {
      editImage: false,
      hiddenKeys: [
        'id',
        'imageable_id',
        'imageable_type',
        'created_at',
        'updated_at'
      ],
      languages: [
        {
          title: 'Italiano',
          code: 'it'
        },
        {
          title: 'Русский',
          code: 'ru'
        },
        {
          title: 'English',
          code: 'en'
        },
        {
          title: 'Estonian',
          code: 'et'
        },
        {
          title: 'Polish',
          code: 'pl'
        },
        {
          title: 'Sweden',
          code: 'sv'
        },
        {
          title: 'Czech',
          code: 'cs'
        }
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
      props: false,
      add: {
        properties: false,
        content_external: false
      },
      section: {
        id: null,
        is_food: false,
        image: {
          src: null,
          file: null
        },
        icon: null,
        content: {
          content: '',
          content_external: '',
          content_first_paragraph: ''
        },
        properties: {
          health: null,
          origin: null,
          property: null,
          season: null,
          storage: null,
          taste: null,
          usage: null
        },
        children: [],
        nutrients: [],
        metals: [],
        vitamins: []
      },
      pending: {
        section: false
      }
    }
  },
  mounted () {
    this.getSection()
    this.subscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type.includes('SET_IMG')) {
        this.section.image = this.$store.getters.GET_IMG
        console.log(this.section.image, this.$store.getters.GET_IMG)
      }
    })
  },
  methods: {
    toSection (item) {
      window.sessionStorage.setItem('section_id', item.id)
      this.$router.push({ name: 'admin.sections', params: { id: item.id } }).then(() => {
        this.getSection()
      })
    },
    getSection () {
      this.pending.section = true
      this.$http.get(`api/v2/auth/sections/${window.sessionStorage.getItem('section_id')}`)
        .then((response) => {
          this.section = response.data.section
          this.pending.section = false
        })
        .catch(() => {
          this.pending.section = false
        })
    },
    translate (lang) {
      this.$http.post('api/v2/translate', {
        id: this.section.id,
        target_lang: lang.code
      })
        .then(response => {
          console.log(response.data)
        })
    },
    addToSection (add) {
      this.add[add] = true
      this.props = true
    },
    closeAddToSection () {
      this.props = false
      Object.keys(this.add).forEach(key => {
        this.add[key] = false
      })
    },
    createNew () {
      this.$store.dispatch('setId', this.section.id)
        .then(() => {
          this.$router.push({ name: 'admin.create' }).then()
        })
    },
    submit () {
      this.$http.patch(`api/v2/auth/sections/${window.sessionStorage.getItem('section_id')}`, this.section)
        .then((response) => {
          console.log(response.data)
        })
    },
    changeImage () {
      this.$store.dispatch('setImageDialog', true)
    },
    openDialog () {
      this.props = true
    },
    closeDialog () {
      this.props = false
      this.$store.dispatch('setImageDialog', false)
    },
    openAddNewImageDialog () {
      this.closeDialog()
      this.editImage = true
    },
    closeAddNewImageDialog () {
      this.editImage = false
    },
    previewNewImage (file) {
      this.section.image.src = URL.createObjectURL(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        this.section.image.file = reader.result
      }
      reader.readAsDataURL(file)
    }
  },
  computed: {
    newImage () {
      return this.$store.getters.GET_IMAGE_DIALOG
    },
    loading () {
      return this.$store.getters.GET_LOADING
    },
    headers () {
      const headers = [
        {
          value: 'name',
          sortable: true
        },
        {
          value: 'route',
          sortable: true
        },
        {
          value: 'created_at',
          sortable: true
        },
        {
          value: 'actions',
          sortable: false
        },
        { value: 'data-table-expand' }
      ]
      headers.forEach((header) => {
        if (!header.value.includes('data-table-expand')) {
          header.text = this.$t(`content.tables.child_table.${header.value}`)
        }
      })
      return headers
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    }
  },
  beforeDestroy () {
    this.subscription()
  }
}
</script>

<style scoped lang="scss">

.lbm_section-card {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: minmax(100px, 400px) auto;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  & .lbm_section-card__image {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    max-height: 400px;
  }
  & .lbm_section-card__editing {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
  & .lbm_section-card__additions {
    padding: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: min-content;
    grid-row-gap: 15px;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
}
.lbm_section-card__properties {
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, calc(100% / 3));
  grid-column-gap: 5px;
  grid-row-gap: 25px;
  padding: 25px;
}
.lbm_section-card__nutrients {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 48px;
  grid-row-gap: 45px;
  grid-column-gap: 15px;
}
</style>
